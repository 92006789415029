/*  DIALOG  */
.p-dialog-header-icon {
  border-radius: 50%;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 700;
  font-size: 21px;
  line-height: 25px;
}

.p-dialog .p-dialog-header {
  padding-bottom: 0;
}

.p-dialog .p-dialog-content {
  // overflow: visible;
  // overflow-x: hidden;
  padding: 0 24px 24px 24px;
}

/*  CONFIRM DIALOG  */
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}

/*  DIALOG SECTION (custom  */
.dialog-section {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__subtitle {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__subtitle-w-step {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    &__steps {
      display: flex;
      gap: 0.4rem;
      flex-wrap: wrap;
      margin-top: 8px;
    }
  }

  &__confirmation {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;

    &__icon {
      padding-top: 24px;
      .pi {
        font-size: 48px;
        color: var(--primary-500);
      }
    }

    &__text {
      width: 100%;
      text-align: center;
    }

    &__message {
      width: 100%;
    }
  }

  &__content,
  &__sub-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: auto;
    overflow-y: auto;

    &__form {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    &__columns {
      display: grid;

      &__col-one {
        display: flex;
        flex-direction: column;
        gap: 8px;
        border-right: 1px solid var(--surface-border);

        img {
          width: 96px;
          height: 96px;
        }
      }

      &__col-two {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding-right: 8px;
        overflow-y: auto;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__left {
      display: flex;
      flex-direction: row;
      gap: 8px;
      justify-content: flex-start;
      align-items: center;
    }

    &__right {
      display: flex;
      flex-direction: row;
      gap: 8px;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

.icon-text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 12px;
}

.dialog-icon-style {
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  padding: 10px;
}

.severity {
  &__info {
    color: var(--blue-600);
    background-color: var(--blue-50);
  }

  &__success {
    color: var(--green-600);
    background-color: var(--green-50);
  }

  &__error {
    color: var(--red-600);
    background-color: var(--red-50);
  }

  &__warn {
    color: var(--yellow-600);
    background-color: var(--yellow-50);
  }
}

.description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  justify-content: space-between;
}

.dialog-lg {
  width: 992px;
}

@media only screen and (max-width: 1024px) {
  .dialog-lg {
    width: 840px;
  }

  .p-dialog .p-dialog-header {
    padding: 12px 24px 0 24px;
  }

  .p-dialog .p-dialog-content {
    padding: 0 24px 12px 24px;
  }
}
