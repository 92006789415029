:host ::ng-deep .p-datepicker table td.p-datepicker-today > span.p-highlight {
  background: var(--highlight-bg) !important;
}

.p-datepicker table td > span {
  margin-left: 4;
  margin-right: 4;
  width: 2rem;
  height: 2rem;
}

.p-datepicker table td > span.p-highlight {
  background: var(--highlight-bg);
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
  background: var(--highlight-bg);
}

.p-datepicker table td > span:focus {
  box-shadow: 0 0 0 0.2rem var(--focus);
}

.p-datepicker .p-timepicker span {
  font-size: 14px;
}

.p-datepicker .p-datepicker-header {
  padding: 0;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  box-shadow: 0 0 0 0.2rem var(--focus);
}

.p-datepicker-title {
  button {
    font-size: 14px;
  }
}

.p-datepicker
  .p-datepicker-header
  .p-datepicker-title
  .p-datepicker-year:enabled:hover,
.p-datepicker
  .p-datepicker-header
  .p-datepicker-title
  .p-datepicker-month:enabled:hover {
  color: var(--primary-500);
}

.p-datepicker:not(.p-disabled)
  .p-monthpicker
  .p-monthpicker-month:not(.p-disabled):focus {
  box-shadow: 0 0 0 0.2rem var(--focus);
}

.p-datepicker:not(.p-disabled)
  table
  td
  span:not(.p-highlight):not(.p-disabled):focus {
  box-shadow: 0 0 0 0.2rem var(--focus);
}

.p-datepicker table td,
.p-datepicker table th,
.p-datepicker table tr,
.p-datepicker table thead,
.p-datepicker table tbody,
.p-datepicker table tfoot {
  border-color: none;
  border-style: none;
  border-width: 0;
  padding: 0;
  text-align: center;
  font-size: 14px;
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
  font-size: 14px;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  padding: 0 4px;
}

.p-datepicker:not(.p-disabled)
  .p-yearpicker
  .p-yearpicker-year:not(.p-disabled):focus {
  box-shadow: 0 0 0 0.2rem var(--focus);
}

.p-datepicker .p-yearpicker .p-yearpicker-year {
  font-size: 14px;
}

.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  background: var(--highlight-bg);
}

.p-datepicker .p-monthpicker .p-monthpicker-month {
  font-size: 14px;
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  background: var(--highlight-bg);
}

.p-datepicker .p-datepicker-buttonbar {
  padding: 0;

  .p-button {
    color: var(--primary-500);

    &:enabled:hover {
      background: rgba(52, 39, 205, 0.04);
      color: var(--primary-500);
    }

    &:enabled:active {
      background: rgba(52, 39, 205, 0.16);
      color: var(--primary-500);
    }

    &:enabled:focus {
      background: rgba(52, 39, 205, 0.16);
      color: var(--primary-500);
    }
  }
}

.p-datepicker {
  min-width: 160px;
}

.p-datepicker:not(.p-datepicker-inline) {
  z-index: 102202 !important;
  padding: 4px;
  // transform: translate(50%, 50%);
}

.p-datepicker .p-timepicker {
  border-top: none;
  padding: 0;
}

/* P-CALENDAR */
.p-calendar {
  width: 100%;
}

.p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
  box-shadow: 0 0 0 0.2rem var(--focus);
  border-color: var(--primary-500);
}
