.display-4 {
  color: var(--text-color);
  font-size: 96px;
  font-weight: 700;
  line-height: 100%;
  margin: 0;
}

.display-2 {
  color: var(--text-color);
  font-size: 64px;
  font-weight: 700;
  line-height: 100%;
  margin: 0;
}

.display-1 {
  color: var(--text-color);
  font-size: 48px;
  font-weight: 700;
  line-height: 100%;
  margin: 0;
}

.heading-1 {
  color: var(--text-color);
  font-size: 40px;
  font-weight: 700;
  line-height: 120%;
  margin: 0;
}

.heading-2 {
  color: var(--text-color);
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  margin: 0;
}

.heading-3 {
  color: var(--text-color);
  font-size: 28px;
  font-weight: 700;
  line-height: 120%;
  margin: 0;
}

.heading-4 {
  color: var(--text-color);
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;
  margin: 0;
}

.heading-5 {
  color: var(--text-color);
  font-size: 20px;
  font-weight: 700;
  line-height: 120%;
  margin: 0;
}

.heading-6 {
  color: var(--text-color);
  font-size: 18px;
  font-weight: 700;
  line-height: 120%;
  margin: 0;
}

.heading-7 {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 700;
  line-height: 120%;
  margin: 0;
}

.p-large {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
}

.p-large-bold {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  margin: 0;
}

.p-default {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
}

.p-default-semibold {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  margin: 0;
}

.p-default-bold {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 700;
  line-height: 150%;
  margin: 0;
}

.p-default-link {
  color: var(--text-color-tertiary);
  font-size: 14px;
  font-weight: 700;
  line-height: 150%;
  text-decoration: underline;
  margin: 0;
  transition: 400ms;
}

.p-default-link:hover {
  color: var(--text-color-secondary);
}

.p-default-italic {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  font-style: italic;
  margin: 0;
}

.p-small {
  color: var(--text-color);
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
}

.p-small-bold {
  color: var(--text-color);
  font-size: 12px;
  font-weight: 700;
  line-height: 150%;
  margin: 0;
}

.p-small-italic {
  color: var(--text-color);
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  font-style: italic;
  margin: 0;
}

.text-secondary {
  color: var(--text-color-secondary);
}

.text-secondary-default {
  color: var(--text-color-secondary);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
}

.text-tertiary {
  color: var(--text-color-tertiary);
}

.text-highlight {
  color: var(--primary-500);
}

.text-info {
  color: var(--blue-600);
}

.text-success {
  color: var(--green-600);
}

.text-error {
  color: var(--red-600);
}

.text-warning {
  color: var(--yellow-600) !important;
}

.text-warning-secondary {
  color: var(--orange-600) !important;
}

.text-info-hard {
  color: var(--blue-500) !important;
}

.text-success-hard {
  color: var(--green-500) !important;
}

.text-error-hard {
  color: var(--red-500) !important;
}

.text-warning-hard {
  color: var(--yellow-500) !important;
}

.p-tooltip {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.tooltip-invalid {
  .p-tooltip-text {
    background-color: var(--red-600) !important;
  }

  .p-tooltip-arrow {
    color: var(--red-600) !important;
  }
}

.tooltip-warning {
  .p-tooltip-text {
    background-color: var(--yellow-600) !important;
  }

  .p-tooltip-arrow {
    color: var(--yellow-600) !important;
  }
}

// TODO: REVER O NOME DAS CLASSES DAS CORES A SEGUIR,
// SE PODE SUBSTITUIR POR OUTRO NOME QUE JÁ TENHA NO PROJETO,
// OU SERAO MANTIDAS AS NOVAS CLASSES
.message-info {
  color: var(--text-color-secondary);
}

.message-warn {
  color: var(--yellow-700);
}

.message-error {
  color: var(--red-500);
}

.message-success {
  color: var(--green-700);
}
