:host ::ng-deep .p-message-custom {
  background-color: var(--bluegray-100);
  border: solid var(--bluegray-500);
  border-width: 0 0 0 6px;
  color: var(--bluegray-900);
}

.p-message .p-message-close:focus {
  box-shadow: 0px 0px 0px 3.2px var(--focus);
}

.input-error-message {
  font-style: normal;
  font-weight: 350;
  font-size: 12px !important;
  line-height: 100%;
  color: var(--text-color-secondary);
  padding-top: 8px;
}

.p-message {
  margin: 0;
}

.p-message .p-message-wrapper {
  padding: 8px 12px;
  display: flex;
  align-items: center;
}

.p-message-summary,
.p-message-detail {
  font-size: 14px;
}

.p-message-close.p-link {
  margin-left: auto;
  overflow: hidden;
  position: relative;
}

.p-message .p-message-close {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  position: relative;
}

.pi {
  font-size: medium;
}

.p-message .p-icon {
  width: 16px;
  height: 16px;
}

.p-message .p-message-icon {
  font-size: none;
  margin-right: 0.5rem;
  display: flex;
}

.p-message.p-message-info {
  background: var(--blue-100);
  border: solid var(--blue-500);
  border-width: 0 0 0 6px;
  color: var(--blue-900);
}
