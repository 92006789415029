/* button RAISED */
// primary button
.p-button.p-button-raised {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  height: 32px;
  width: auto;
  border-radius: 4px;
  box-shadow: none;
  padding: 0 12px;

  &.primary {
    color: var(--text-primary);
    border: 1px solid var(--primary-500);
    background-color: var(--primary-500);
  }

  &.destructive {
    color: var(--text-primary);
    border: 1px solid var(--red-600);
    background-color: var(--red-600);
  }

  &:enabled:hover {
    &.primary {
      color: var(--text-primary);
      border: 1px solid var(--primary-400);
      background: var(--primary-400);
    }

    &.destructive {
      color: var(--text-primary);
      border: 1px solid var(--red-500);
      background-color: var(--red-500);
    }
  }

  &:enabled:active {
    &.primary {
      color: var(--text-primary);
      background: var(--primary-700);
      border: 1px solid var(--primary-700);
    }

    &.destructive {
      color: var(--text-primary);
      border: 1px solid var(--red-500);
      background-color: var(--red-500);
    }
  }

  &:enabled:focus {
    box-shadow: 0px 0px 0px 3.2px var(--focus);
    color: var(--text-primary);

    &.primary {
      background: var(--primary-500);
      border: 1px solid var(--primary-500);
    }

    &.destructive {
      color: var(--text-primary);
      border: 1px solid var(--red-500);
      background-color: var(--red-500);
    }
  }
}

/* button OUTLINE */
// secondary button
.p-button.p-button-outlined {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  height: 32px;
  width: auto;
  border-radius: 4px;

  &.primary {
    color: var(--primary-500);
    border: 1px solid var(--primary-500);
  }

  &.destructive {
    color: var(--text-primary);
    border: 1px solid var(--red-600);
  }

  &:enabled:hover {
    &.primary {
      color: var(--primary-500);
      border: 1px solid var(--primary-500);
      background-color: rgba(101, 60, 150, 0.1);
    }

    &.destructive {
      color: var(--text-primary);
      border: 1px solid var(--red-500);
      background-color: var(--red-50);
    }
  }

  &:enabled:active {
    &.primary {
      color: var(--primary-400);
      border: 1px solid var(--primary-500);
      background-color: rgba(101, 60, 150, 0.1);
    }

    &.destructive {
      color: var(--text-primary);
      border: 1px solid var(--red-500);
      background-color: var(--red-50);
    }
  }

  &:enabled:focus {
    box-shadow: 0px 0px 0px 3.2px var(--focus);

    &.primary {
      color: var(--primary-400);
      border: 1px solid var(--primary-500);
      background-color: rgba(101, 60, 150, 0.1);
    }

    &.destructive {
      color: var(--text-primary);
      border: 1px solid var(--red-500);
      background-color: var(--red-50);
    }
  }

  &:disabled {
    opacity: 0.4;
  }
}

/* button TEXT */
// tertiary button
.p-button.p-button-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  height: 32px;
  width: auto;
  border-radius: 4px;

  &.primary {
    color: var(--primary-500);
  }

  &.secondary {
    color: var(--bluegray-500);
  }

  &.destructive {
    color: var(--red-600);
  }

  &.no-effect {
    background-color: var(--surface-section);
    box-shadow: 0px 0px 0px 0px transparent;
    cursor: default;
  }

  &:enabled:hover {
    &.primary {
      background: rgba(101, 60, 150, 0.1);
      color: var(--primary-500);
    }

    &.secondary {
      background: rgba(100, 116, 139, 0.04);
      color: var(--bluegray-500);
    }

    &.no-effect {
      background-color: var(--surface-section);
      box-shadow: 0px 0px 0px 0px transparent;
      cursor: default;
    }

    &.destructive {
      background-color: var(--red-50);
      color: var(--red-500);
    }
  }

  &:enabled:active {
    &.primary {
      background: rgba(101, 60, 150, 0.1);
      color: var(--primary-500);
    }

    &.secondary {
      background: rgba(100, 116, 139, 0.16);
      color: var(--bluegray-500);
    }

    &.no-effect {
      background-color: var(--surface-section);
      box-shadow: 0px 0px 0px 0px transparent;
      cursor: default;
    }

    &.destructive {
      background-color: var(--red-50);
      color: var(--red-500);
    }
  }

  &:enabled:focus {
    box-shadow: 0px 0px 0px 3.2px var(--focus);

    &.primary {
      background: rgba(101, 60, 150, 0.1);
      color: var(--primary-500);
    }

    &.secondary {
      background: rgba(100, 116, 139, 0.16);
      color: var(--bluegray-500);
    }

    &.no-effect {
      background-color: var(--surface-section);
      box-shadow: 0px 0px 0px 0px transparent;
      cursor: default;
    }

    &.destructive {
      background-color: var(--red-50);
      color: var(--red-500);
    }
  }
}

/* button CARD */
.p-button.p-button-card {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18.62px;
  height: auto;
  width: auto;
  border-radius: 4px;
  background-color: var(--surface-card);
  color: var(--text-primary);
  border: 1px solid var(--input-border);

  &:enabled:hover:not(.disabled) {
    background: var(--primary-100);
    border: 1px solid var(--primary-500);
  }

  &:enabled:active {
    background: var(--primary-100);
    border: 1px solid var(--primary-500);
  }

  &:enabled:focus {
    box-shadow: 0px 0px 0px 3.2px var(--focus);
  }

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.button-card {
  &__content {
    display: flex;
    align-items: center;
    // justify-content: center;
    gap: 8px;

    .pi {
      color: var(--text-primary) !important;
    }

    &__text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      gap: 4px;

      &:hover:not(.disabled) {
        p {
          color: var(--primary-800) !important;
        }
      }

      &.disabled {
        p {
          color: var(--text-primary) !important;
        }
      }
    }
  }
}

/*  SPLITBUTTON  */
.p-splitbutton .p-splitbutton-defaultbutton {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18.62px;
  height: 32px;
  border-radius: 4px;
  box-shadow: none;
  color: var(--text-primary);
  border: 2px solid var(--bluegray-300);
  background-color: var(--bluegray-300);

  &:enabled:hover {
    color: var(--text-primary);
    background: var(--bluegray-400);
    border: 2px solid var(--bluegray-400);
  }

  &:enabled:active {
    color: var(--text-primary);
    background: var(--bluegray-600);
    border: 2px solid var(--bluegray-600);
  }

  &:enabled:focus {
    color: var(--text-primary);
    background: var(--bluegray-300);
    border: 2px solid var(--bluegray-300);
    box-shadow: 0px 0px 0px 3.2px var(--focus);
  }

  .pi-chevron-down:before {
    content: "\e903";
  }
}

:host
  ::ng-deep
  .p-element
  .p-splitbutton-menubutton
  .p-button
  .p-component
  .p-button-icon-only {
  color: var(--text-primary);
  background: var(--bluegray-300);
  border: 2px solid var(--bluegray-300);
}

/*  SELECT BUTTON  */
.p-selectbutton .p-button {
  height: 32px;
  font-size: 14px;

  &:focus {
    box-shadow: 0px 0px 0px 3.2px var(--focus);
  }
}

.p-selectbutton .p-button.p-highlight,
.p-selectbutton .p-button.p-highlight:hover {
  background: var(--primary-500);
  border-color: var(--primary-500);
  color: var(--text-primary);
}

/* button do autocomplete */
.p-autocomplete-dd .p-autocomplete-dropdown {
  background-color: var(--primary-500);
  border: 1px solid var(--primary-500);

  &:not(:disabled):hover {
    background-color: var(--primary-400);
    border: 1px solid var(--primary-400);
  }
}

/* button with icons */
.p-button .p-button-icon-left {
  font-size: 12px;
}

.p-button.p-button-icon-only {
  width: 14px;
  padding: 14px !important;

  .pi {
    font-size: 14px;
  }
}

/* button with dropdown */
.p-menu .p-menuitem-link {
  font-size: 14px;
}

.p-menu .p-menuitem-link:focus {
  box-shadow: 0px 0px 0px 3.2px var(--focus);
}

.p-menu .p-menuitem-link .p-menuitem-icon {
  font-size: 14px;
}

/* SHORTCUT BUTTON */
.p-button-shortcut {
  color: var(--text-color-secondary);
  background-color: transparent;
  padding: 0;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;

  &:enabled:hover {
    color: var(--bluegray-400);
    background-color: transparent;
  }

  &:enabled:active {
    color: var(--bluegray-400);
    background-color: transparent;
    border: none;
  }

  &:enabled:focus {
    box-shadow: 0px 0px 0px 3.2px var(--focus);
    border: none;
  }
}

/* RADIO BUTTON */
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: var(--primary-500);
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--primary-500);
  background: var(--primary-500);
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem var(--focus);
  border-color: var(--primary-500);
}

.p-radiobutton-label {
  font-size: 14px;
}

/* INPUTNUMBER */
p-inputnumber {
  // input {
  //   border-top-right-radius: 0;
  //   border-bottom-right-radius: 0;
  //   // border-right: none;
  // }
}

.p-inputnumber-button-group {
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }
  .p-button.p-button-icon-only {
    padding: 0 !important;
    background: transparent;
    color: var(--primary-500);
    border: 1px solid var(--input-border);
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-right: 4px !important;
  }

  .p-button.p-inputnumber-button-up {
    border-top-right-radius: 4px;
    border-bottom: none;
    padding: 0 10px !important;
  }

  .p-button.p-inputnumber-button-down {
    border-bottom-right-radius: 4px;
    border-top: none;
    padding: 0 10px !important;
  }
}

p-inputnumber.p-inputnumber-clearable
  .p-inputnumber-buttons-stacked
  .p-inputnumber-clear-icon {
  right: 1.25rem;
}
