.p-steps {
  padding: 12px 0;
  background-color: var(--surface-card);
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.p-steps .p-steps-item .p-steps-number {
  font-size: 14px;
  line-height: 150%;
  font-weight: 700;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: var(--primary-0);
  color: var(--primary-500);
}

.p-steps-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 100%;
  text-align: center;
  color: var(--text-color);
  padding-bottom: 10px;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 700;
  color: var(--primary-500);
}

.p-steps .p-steps-item.p-disabled {
  background-color: transparent;
}

// personalizações
.step {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  background-color: none;
  border: 1px solid var(--surface-c);
}
