/* You can add global styles to this file, and also import other style files */
// @import url("https://fonts.googleapis.com/css2?family=Days+One&display=swap%22");
// @import "../node_modules/bootstrap/scss/bootstrap.scss";

/* styles.scss */
@import "typeface-open-sans";
@import url("https://fonts.googleapis.com/css2?family=Days+One&display=swap");
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "./app/styles/input";
@import "./app/styles/button";
@import "./app/styles/breadcrumb";
@import "./app/styles/texts";
@import "./app/styles/messages";
@import "./app/styles/grid";
@import "./app/styles/table";
@import "./app/styles/orderlist";
@import "./app/styles/datepicker";
@import "./app/styles/dialog";
@import "./app/styles/custom_dropdown";
@import "./app/styles/shepherd";
@import "./app/styles/password";
@import "./app/styles/inplace";
@import "./app/styles/overlaypanel";
@import "./app/styles/picklist";
@import "./app/styles/text_utils";
@import "./app/styles/tag";
@import "./app/styles/steps";
@import "./app/styles/panel";
@import "./app/styles/field";
@import "./app/styles/timeline";
@import "./app/styles/icon";
@import "flag.css";
@import "./app//styles/load";

:root {
  /* COR PRINCIPAL */
  --primary-0: #ede8f3;
  --primary-100: #d1c5e1;
  --primary-200: #b39fcd;
  --primary-300: #9679b9;
  --primary-400: #815caa;
  --primary-500: #6c419c;
  --primary-600: #653c96;
  --primary-700: #59348d;
  --primary-800: #502e84;
  --primary-900: #402473;

  /*  SURFACES  */
  --surface-ground: #eff3f8;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dfe7ef;
  --surface-hover: #f6f9fc;
  --surface-body: #e7e7ec;
  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --highlight-bg: #f0f1ff;
  --focus: rgba(130, 56, 221, 0.16);
  --input-border: #ced4da;

  /*  TEXT  */
  --text-primary: #ffffff;
  --text-color: #495057;
  --text-color-secondary: #6c757d;
  --text-color-tertiary: var(--bluegray-300);

  /*  BLUEGRAY  */
  --bluegray-50: #f7f8f9;
  --bluegray-100: #dadee3;
  --bluegray-200: #bcc3cd;
  --bluegray-300: #9fa9b7;
  --bluegray-400: #818ea1;
  --bluegray-500: #64748b;
  --bluegray-600: #556376;
  --bluegray-700: #465161;
  --bluegray-800: #37404c;
  --bluegray-900: #282e38;

  /*  GREEN  */
  --green-50: #f4fcf7;
  --green-100: #caf1d8;
  --green-200: #a0e6ba;
  --green-300: #76db9b;
  --green-400: #4cd07d;
  --green-500: #22c55e;
  --green-600: #1da750;
  --green-700: #188a42;
  --green-800: #136c34;
  --green-900: #0e4f26;

  /*  YELLOW  */
  --yellow-50: #fefbf3;
  --yellow-100: #faedc4;
  --yellow-200: #f6df95;
  --yellow-300: #f2d066;
  --yellow-400: #eec137;
  --yellow-500: #eab308;
  --yellow-600: #c79807;
  --yellow-700: #a47d06;
  --yellow-800: #816204;
  --yellow-900: #5e4803;

  /*  BLUE  */
  --blue-50: #f3f9ff;
  --blue-100: #d0e1fd;
  --blue-200: #abc9fb;
  --blue-300: #85b2f9;
  --blue-400: #609af8;
  --blue-500: #3b82f6;
  --blue-600: #326fd1;
  --blue-700: #295bac;
  --blue-800: #204887;
  --blue-900: #183462;

  /*  RED  */
  --red-50: #fff5f5;
  --red-100: #ffd0ce;
  --red-200: #ffaca7;
  --red-300: #ff8780;
  --red-400: #ff6259;
  --red-500: #ff3d32;
  --red-600: #d9342b;
  --red-700: #b32b23;
  --red-800: #8c221c;
  --red-900: #661814;

  /*  ORANGE  */
  --orange-50: #fff8f3;
  --orange-100: #feddc7;
  --orange-200: #fcc39b;
  --orange-300: #fba86f;
  --orange-400: #fa8e42;
  --orange-500: #f97316;
  --orange-600: #d46213;
  --orange-700: #ae510f;
  --orange-800: #893f0c;
  --orange-900: #642e09;

  /*  PURPLE  */
  --purple-50: #fbf7ff;
  --purple-100: #ead6fd;
  --purple-200: #dab6fc;
  --purple-300: #c996fa;
  --purple-400: #b975f9;
  --purple-500: #a855f7;
  --purple-600: #8f48d2;
  --purple-700: #763cad;
  --purple-800: #5c2f88;
  --purple-900: #432263;

  /*  PINK  */
  --pink-50: #fef6fa;
  --pink-100: #fad3e7;
  --pink-200: #f7b0d3;
  --pink-300: #f38ec0;
  --pink-400: #f06bac;
  --pink-500: #ec4899;
  --pink-600: #c93d82;
  --pink-700: #a5326b;
  --pink-800: #822854;
  --pink-900: #5e1d3d;

  /*  CYAN  */
  --cyan-50: #f3fbfd;
  --cyan-100: #c3edf5;
  --cyan-200: #94e0ed;
  --cyan-300: #65d2e4;
  --cyan-400: #35c4dc;
  --cyan-500: #06b6d4;
  --cyan-600: #059bb4;
  --cyan-700: #047f94;
  --cyan-800: #036475;
  --cyan-900: #024955;

  /*  TEAL  */
  --teal-50: #f3fbfb;
  --teal-100: #c7eeea;
  --teal-200: #9ae0d9;
  --teal-300: #6dd3c8;
  --teal-400: #41c5b7;
  --teal-500: #14b8a6;
  --teal-600: #119c8d;
  --teal-700: #0e8174;
  --teal-800: #0b655b;
  --teal-900: #084a42;

  /*  INDIGO  */
  --indigo-50: #f7f7fe;
  --indigo-100: #dadafc;
  --indigo-200: #bcbdf9;
  --indigo-300: #9ea0f6;
  --indigo-400: #8183f4;
  --indigo-500: #6366f1;
  --indigo-600: #5457cd;
  --indigo-700: #4547a9;
  --indigo-800: #363885;
  --indigo-900: #282960;
}

* {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

body {
  background-color: var(--surface-body);
  overflow: hidden;
  color: var(--text-color);
}

hr {
  margin: 0;
  opacity: 1;
  border-bottom: 1px solid var(--surface-border);
  border-top: none;
}

a {
  color: var(--text-color);
  text-decoration: none;
  font-size: 14px;
}

a:hover {
  color: var(--text-color);
}

ol,
ul,
dl {
  padding: 0;
  margin: 0;
  font-size: 14px;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: var(--surface-border);
  border-style: solid;
  border-width: thin;
}

:host ::ng-deep .p-toast {
  margin-top: 150px;
  top: 150px !important;
}

.c-section {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__toolbar__container {
    position: sticky;
    top: 0;
    background: transparent;
    z-index: 2;
  }

  &__messages {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__table__content {
    max-width: 1800px;
    width: 100%;
    border-radius: 4px;
  }

  &__content {
    max-width: 1800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    background-color: var(--surface-a);
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 24px;

    &__messages {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__tabview {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &__title {
        p {
          color: #495057;
          font-family: "Open Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
        }
      }

      &__message {
        display: flex;
        flex-direction: column;
        padding: 12px;
      }

      &__text {
        display: flex;
        flex-direction: column;
        gap: 12px;
        max-width: 512px;

        p {
          color: var(--Text-color);
          font-family: "Open Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }

      &__tabpanel {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 12px;
      }

      &__form {
        display: flex;
        flex-direction: column;
        gap: 12px;

        &__button-form {
          display: flex;
          gap: 12px;
          padding-top: 12px;
          justify-content: space-between;
        }

        &__icon-td {
          padding-right: 8px;
        }
      }
    }

    &__panel {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;
      }

      &__messages {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      &__form {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &__message {
          padding-left: 262px;
          display: flex;
          flex-direction: column;
          gap: 4px;
        }

        &__item {
          display: flex;
          flex-direction: row;
          gap: 12px;

          @media (max-width: 1080px) {
            display: flex;
            flex-direction: column;
            gap: 0px;
          }

          &__label {
            width: 100%;
            min-width: 160px;
            max-width: 250px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            padding-top: 6px;
            text-align: right;

            @media (max-width: 1080px) {
              justify-content: flex-start;
              text-align: left;
              padding: 0px;
            }
          }

          &__field {
            display: flex;
            gap: 4px;

            &__checkbox {
              display: flex;
              flex-direction: column;
              padding-top: 7px;

              &-group {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 4px;
              }
            }

            &__switch {
              display: flex;
              align-items: center;
              padding-top: 5px;
            }

            &__icon {
              display: flex;
              align-items: center;
              width: fit-content;
              min-width: 35px;
              height: 35px;
              justify-content: center;
            }

            &__trailling {
              display: flex;
              align-items: center;
            }
          }

          &__char {
            display: flex;
            width: 35px;
            justify-content: center;
          }

          &__message {
            display: flex;
            padding-top: 8px;
            flex: 1;
            gap: 4px;
          }

          &__divider {
            padding: 4px 0;
          }
        }
      }

      &__form-lg {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &__item {
          display: flex;
          justify-content: left;
          align-items: center;
          align-self: stretch;
          gap: auto;

          @media (max-width: 1080px) {
            display: flex;
            flex-direction: column;
            gap: 0px;
          }

          @media (max-width: 1400px) {
            display: flex;
            justify-content: space-between;
          }

          &__label {
            width: 100%;
            min-width: 160px;
            max-width: 480px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-right: 240px;
            // padding-top: 6px;
            // text-align: right;     }

            @media (max-width: 1080px) {
              justify-content: flex-start;
              text-align: left;
              padding: 8px;
              width: 100%;
              min-width: 160px;
              max-width: 320px;
            }
          }

          &__field {
            width: 100%;
            // max-width: 512px;
            display: flex;
            justify-content: flex-start;

            &__radio {
              display: flex;
              gap: 24px;
            }

            &__switch {
              display: flex;
              align-items: center;
              gap: 8px;
              // padding-top: 5px;
            }

            &__icon {
              display: flex;
              align-items: center;
              width: fit-content;
              min-width: 35px;
              height: 35px;
              justify-content: center;
            }

            &__table {
              display: flex;
              flex-direction: column;
              gap: 12px;
              align-items: flex-start;
            }
          }
        }
      }

      &__panels {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
      }

      &__columns {
        display: flex;
        flex-direction: row;
        width: 100%;

        &__column {
          display: flex;
          flex-direction: column;
          gap: 24px;

          &__title {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          &__content {
            display: flex;
            flex-direction: column;
            gap: 24px;
          }
        }
      }
    }
  }
}

// DIALOG-FILTER
.c-dialog-filter {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  padding: 24px 0;

  &__left,
  &__right {
    border-radius: 4px;
    border: 1px solid var(--surface-border);
    background: var(--surface-b);
    padding: 12px;

    &__content {
      display: flex;
      flex-direction: column;
      gap: 24px;

      &__item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &__label {
          display: flex;
          flex-direction: row;
          align-items: center;

          i {
            padding-right: 8px;
          }
        }

        &__field {
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__left,
    &__right {
      display: flex;
      gap: 8px;
    }
  }
}

.c-section__content__panel__columns > div {
  padding: 0 12px;
  border-right: 1px solid var(--surface-border);
}

.c-section__content__panel__columns > div:first-child {
  padding-left: 0;
}

.c-section__content__panel__columns > div:last-child {
  padding-right: 0;
  border-right: none;
}

.info-icon {
  display: flex;
  align-items: center;
  width: 35px;
  justify-content: center;
}

.toolbar-inside-panel {
  border: none;
  padding-left: 0;
  padding-right: 0;
}

.multivalued-column {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3px;

  &__left {
  }

  &__right {
  }
}

.p-disabled:not(
    .p-button,
    .p-inputswitch,
    .p-menuitem,
    .p-paginator-element,
    .p-accordion-header,
    .p-panelmenu-header
  ),
.p-component:disabled:not(.p-button, .p-inputswitch),
.ng-select-disabled {
  background-color: var(--bluegray-50);
  opacity: 1 !important;
  color: var(--text-color);
}

.inOperation {
  background-color: var(--yellow-100);
  border: 1px solid var(--yellow-200) !important;
}

.p-inputMask {
  width: 100%;
}

/**
 * Style geral aplicado a todas as tabelas para add 3 pontinhos (...) quando o texto da <td> passar do valor definido
 */
p-table tbody tr td {
  max-width: 15ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/**
* Style para definir a configuração de tamanho e estilo da fonte do componente <p-confirmPopup>
*/
.p-confirm-popup-message {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
}

.hidesDiv {
  display: none;
}

.no-results {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  &:hover {
    background-color: var(--surface-section);
  }
}

.border-invalid-no-height {
  border: 1px solid var(--red-500) !important;
  border-radius: 4px;
}

.border-invalid {
  border: 1px solid var(--red-500) !important;
  border-radius: 4px;
  height: auto;
}

/* para estilizar o p-inputnumber e o p-calendar */
.border-invalid-especific {
  .p-inputtext {
    border: 1px solid var(--red-500) !important;
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.border-warning {
  border: 1px solid var(--yellow-500) !important;
  border-radius: 4px;
  height: auto;
}

/* para estilizar o p-inputnumber e o p-calendar */
.border-warning-especific {
  .p-inputtext {
    border: 1px solid var(--yellow-500) !important;
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.border-warning-especific-without {
  .p-inputtext {
    border: 1px solid var(--yellow-500) !important;
    border-radius: 4px;
  }
}

.p-toolbar-group-right {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
}

.p-toolbar-group-left {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
}

// cards de acesso nos resumos
.access-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
