.field-2xs {
  width: 42px;
}

.field-xs {
  width: 100px;
}

.field-sm {
  width: 160px;
}

.field-md {
  width: 258px;
}

.field-lg {
  width: 320px;
}

.field-xl {
  width: 512px;
}

.textarea-lg {
  width: 320px;
  height: 105px;
}

.textarea-xl {
  width: 512px;
  height: 105px;
}
